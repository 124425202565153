document.addEventListener('DOMContentLoaded', () => {
    document.querySelectorAll('.c-checkbox__text').forEach((element) => {
        element.addEventListener('click', function () {
            this.querySelector('label').click();
        });
    });

    document.querySelectorAll('label').forEach((element) => {
        element.addEventListener('click', function (e) {
            e.stopPropagation();
        });
    });
});
